<template>
  <v-chip
    v-bind="$attrs"
    outlined
    :small="!Object.keys($attrs).includes('x-small')"
    v-text="gene.gene"
  />
</template>
<script>
  export default {
    props: {
      gene: {
        type: Object,
        default: () => ({}),
      },
      name: {
        type: String,
        default: null,
      },
    },
  }
</script>
